<!--  -->
<template>
  <div class="swiperTop">
    <div class="banner">
      <carousel-3d ref="slideBanner" class="carousel-container" v-if="wallpaperList && wallpaperList.length" autoplay :autoplayTimeout="6000" :autoplayHoverPause="true" :animationSpeed="500" :minSwipeDistance="9999" @after-slide-change="onSildeChange" :space="425" :border="0" :display="3" :perspective="2" :width="696" :height="392" :inverseScaling="95">
        <slide class="carousel-slide" v-for="(item, index) in wallpaperList" :key="index" :index="index">
          <div class="carousel-image-wrapper" v-show="carousel3dShow >= wallpaperList.length - 1">
            <video muted="muted" loop="loop" :src="item.video_url" @loadedmetadata="videoLoaded(index)"></video>
          </div>
        </slide>
      </carousel-3d>
      <div class="carousel-prev" @click="onBannerPrev" v-show="carousel3dShow >= wallpaperList.length - 1"></div>
      <div class="carousel-next" @click="onBannerNext" v-show="carousel3dShow >= wallpaperList.length - 1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    wallpaperList: null,
  },
  data() {
    let self = this;
    return {
      timer: null,
      timerBanner: null,
      timerPlay: null,
      timerResize: null,
      carousel3dShow:0,
    };
  },
  computed: {},
  watch: {},
  created(){

  },
  mounted() {
    this.onSildeChange(0)
  },
  methods: {
    videoLoaded(index){
      this.carousel3dShow = this.carousel3dShow + 1
    },
    onBannerPrev() {
      if (this.timerBanner) return;
      this.$refs.slideBanner.goPrev();
      this.timerBanner = setTimeout(() => {
        clearTimeout(this.timerBanner);
        this.timerBanner = null;
      }, 500);
    },
    onBannerNext() {
      if (this.timerBanner) return;
      this.$refs.slideBanner.goNext();
      this.timerBanner = setTimeout(() => {
        clearTimeout(this.timerBanner);
        this.timerBanner = null;
      }, 500);
    },
    onSildeChange(current) {
      try {
        const el = this.$el;
        const videos = el.querySelectorAll(".banner video");
        videos.forEach((item) => item.pause());
        videos[current].play();
        this.currentActiveBanner == current;
      } catch (error) {
        console.log("error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.swiperTop {
  margin: 0 auto;
  top: 34px;
  width: 1380px;
  position: absolute;
  left: 50%;
  margin-left: -690px;
  .banner {
    position: relative;
    width: 100%;
    height: 400px;
    .carousel-container {
      position: relative;
      transform: translateY(60px);
    }
    .carousel-slide {
      background-color: transparent;
      border: 0;
    }
    .carousel-image-wrapper {
      width: 696px;
      height: 392px;
      border-radius: 20px;
      overflow: hidden;
      background: url("../../assets/images/bd01/border_2025.png") no-repeat;
      background-size: 100% 100%;
      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border: 2px solid transparent;
        border-radius: 20px;
        box-sizing: border-box;
      }
    }
    .carousel-prev {
      position: absolute;
      left: 260px;
      top: 223px;
      width: 40px;
      height: 58px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 8px 8px 8px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &::before {
        content: "";
        width: 20px;
        height: 24px;
        background: url("../../assets/images/bd01/sjiao.png") no-repeat;
        background-size: 100% 100%;
        transform: rotate(180deg);
        margin-right: 4px;

      }
      &:hover {
        background: rgba(0, 0, 0, 0.7);
      }
    }
    .carousel-next {
      position: absolute;
      right: 260px;
      top: 223px;
      width: 40px;
      height: 58px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 8px 8px 8px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &::before {
        content: "";
        width: 20px;
        height: 24px;
        background: url("../../assets/images/bd01/sjiao.png") no-repeat;
        background-size: 100% 100%;
        margin-left: 4px;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.7);
      }
    }
  }
}
</style>
